// FullScreenImageBanner.scss

.full-screen-banner {
    width: 100vw;
    height: 100vh;
    background-image: url('https://pub.assets.meetmo.io/banner_player_9-16_RED.avif');
    background-size: cover;
    background-position: center;
  
    @media (min-width: 768px) {
      background-color: black;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .full-screen-banner .hidden {
    display: none !important;
  }

// Add your styles here
.mbplayer {
  // add your styles here
  margin: '0';
  width: '100%';
  height: '100%';
}
  
.mbplayer .hidden {
  display: none !important;
}